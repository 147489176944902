import { createContext, useContext, useState, useEffect } from "react";
import { useMetaMask } from "metamask-react";
import { useGoogleLogin, useGoogleLogout } from "react-google-login";
import { toast } from 'react-toastify';

import { MetamaskAuth, GoogleAuth } from "../Utils/ApiUtils";
import signMessage from "../Utils/signer";
import { handleChainChange } from "../Utils/common";

import { providerHandler } from "../web3/contractInteraction";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { status, connect, account } = useMetaMask();
    
    const [loginState, setLoginState] = useState(false);
    const [metamaskStatus, setMetamaskStatus] = useState("initializing");
    const [googleStatus, setGoogleStatus] = useState("initializing");
    const [loginModal, setLoginModal] = useState(false);

    const loginToast = "loginToast";

    useEffect(() => {
        setMetamaskStatus(status);
    }, [status]);

    useEffect(() => {
        const userType = localStorage.getItem("userType");
        
        if (userType && userType === "metamask") {
            if(status === "connected" && (account === localStorage.getItem("account").toLowerCase())) {
                (async () => {
                    await handleChainChange();
                    const account = await providerHandler();
                    setLoginState({type: "metamask", account: account});
                })();
            } else {
                setLoginState(false);
                authLogout();
            }
        }
    }, [account]);

    const metamaskLogin = async () => {
        setMetamaskStatus("connecting");
        await handleChainChange();
        const res = await connect();
        await providerHandler();

        try {
            if (account !== null) {
                const signature = await signMessage(res[0]);
                const resp = await MetamaskAuth(signature);

                if (resp) {
                    localStorage.setItem("userType", "metamask");
                    localStorage.setItem("token", resp.tokenGenerated);
                    localStorage.setItem("account", account);
    
                    setLoginState({ type: "metamask", account: account });
                    setLoginModal(false);
                }
            }
        } catch (e) {
            setMetamaskStatus("notConnected");
            toast(e, {
                type: 'error',
                toastId: loginToast
            });
        }
    };

    const googleLoginHandler = async (res) => {
        setGoogleStatus("connecting");
        const account = localStorage.getItem("account");
        const token = localStorage.getItem("token");

        if ((res && !token) || (res && account !== res.googleId)) {
            try {
                const token = {...res.tokenObj, session_state: "res.session_state.extraQueryParams.authuser"};
                const resp = await GoogleAuth(token);
    
                localStorage.setItem("userType", "google");
                localStorage.setItem("token", resp.tokenGenerated);
                localStorage.setItem("account", res.googleId);

                setGoogleStatus("connected");
                setLoginState({type: "google", account: res.profileObj.name});
                setLoginModal(false);
            } catch (e) {
                toast(e.message, {
                    type: 'error',
                    toastId: loginToast
                });
                setGoogleStatus("notConnected");
            }
        } else if (token && account === res.googleId) {
            setGoogleStatus("connected");
            setLoginState({type: "google", account: res.profileObj.name});
            setLoginModal(false);
        }
    }

    const { signIn: googleLogin } = useGoogleLogin({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        isSignedIn: true,
        accessType: "offline",
        onAutoLoadFinished: () => {if (googleStatus === "initializing") setGoogleStatus("notConnected")},
        onSuccess: (res) => {googleLoginHandler(res);},
        onFailure: (e) => {
            if (e.details.includes("Cookies")) setGoogleStatus("unavailable");
            else console.log("LOGIN ERR: ", e.details);
        }
    });

    const { signOut: googleLogout } = useGoogleLogout({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        onFailure: (e) => {
            if (e.details.includes("Cookies")) setGoogleStatus("unavailable");
            else console.log("LOGOUT ERR: ", e);
        },
        onLogoutSuccess: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("account");
            localStorage.removeItem("userType");
            localStorage.removeItem("adminToken");

            setGoogleStatus("notConnected");
            setLoginState(false); 
        }
    });

    const authLogout = async (reload) => {
        if (loginState.type === "metamask") {
            localStorage.removeItem("token");
            localStorage.removeItem("account");
            localStorage.removeItem("userType");
            localStorage.removeItem("adminToken");

            setLoginState(false);
            setLoginModal(false);
        }
        if (loginState.type === "google") {
            googleLogout();
            setLoginModal(false);
        }
        if (reload) {
            window.location.reload();
        }
    }

    return <AuthContext.Provider value={{
        loginState,
        loginModal,
        metamaskStatus,
        googleStatus,
        setLoginModal,
        metamaskLogin,
        googleLogin,
        authLogout
    }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};