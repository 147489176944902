import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import "./addArtwork.scss"

import { IoCloudUpload } from "react-icons/io5";
import { AiOutlineLoading } from "react-icons/ai";
import { MdDeleteOutline, MdError } from "react-icons/md";

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { prodArtworkSchema, draftArtworkSchema } from '../../../Utils/validationSchema';
import { ApiCall } from "../../../Utils/ApiUtils";
import { AdminApiCall, ApiFileUpload } from '../../../Utils/AdminApiUtils';

export default function AddArtwork() {
    const navigate = useNavigate();
    let addArtworkToast = React.useRef(null);
    const toastId = "toast";
    
    const [artImage, setArtImage] = useState(null);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [imageId, setImageId] = useState(null);

    const [traits, setTraits] = useState([
        {trait_type: "", value: ""}
    ]);

    const [traitOptions, setTraitOptions] = useState([]);
    const [traitsLoading, setTraitsLoading] = useState(true);
    const [artistOptions, setArtistOptions] = useState([]);
    const [artistsLoading, setArtistsLoading] = useState(true);

    const [validationError, setValidationError] = useState({
        imageRef: false,
        title: false,
        quantity: false,
        description: false,
        artistId: false,
        traits: false,
        price: false
    });

    const handleTraitChange = (key, value, i) => {
        let newTraitValues = [...traits];
        newTraitValues[i][key] = value;
        setTraits(newTraitValues);
    }

    const addTraitField = () => {
        setTraits([...traits, {trait_type: "", value: ""}]);
    }

    const removeTraitField = (i) => {
        let newTraitValues = [...traits];
        newTraitValues.splice(i, 1);
        setTraits(newTraitValues);
    }

    const handleImageUpload = async (e) => {
        setUploadingImage(true);
        setArtImage(null);
        if (e.target.files[0])
            try {
                if(e.target.files[0].size > 31457280) {
                    toast("File size bigger than 30MB", {
                        type: 'error'
                    });
                    setUploadingImage(false);
                    return;
                }
                if(!e.target.files[0].type.includes('image')) {
                    toast("Invalid file type.", {
                        type: 'error'
                    });
                    setUploadingImage(false);
                    return;
                }
                const imageResp = await ApiFileUpload(e.target.files[0]);
                setImageId(imageResp.id);
                setArtImage(URL.createObjectURL(e.target.files[0]));
            } catch (e) {
                toast("Upload Error: "+e, {type: 'error'});
                console.log("Upload Error: ", e);
            }
        setUploadingImage(false);
    }

    const handleAddArtwork = async (event) => {
        event.preventDefault();
        setValidationError({
            imageRef: false,
            title: false,
            quantity: false,
            description: false,
            artistId: false,
            traits: false,
            price: false
        });

        const formData = {
            title: event.target.title.value,
            traits: traits,
            progressStatus: event.nativeEvent.submitter.value,
            isFeatured: event.target.isFeatured.checked,
            isTrending: event.target.isTrending.checked
        }
        if (event.target.quantity.value || event.nativeEvent.submitter.value === "production") formData.quantity = event.target.quantity.value;
        if (event.target.description.value || event.nativeEvent.submitter.value === "production") formData.description = event.target.description.value;
        if (event.target.artistId.value || event.nativeEvent.submitter.value === "production") formData.artistId = event.target.artistId.value;
        if (event.target.price.value || event.nativeEvent.submitter.value === "production") formData.price = event.target.price.value;
        if (imageId) formData.imageRef = imageId;
        
        console.log(formData);

        addArtworkToast.current = toast.loading("Validating", {toastId: toastId});
        try {
            if (formData.progressStatus === "draft")
                await draftArtworkSchema.validate(formData, {
                    abortEarly: false
                });
            else
                await prodArtworkSchema.validate(formData, {
                    abortEarly: false
                });
            toast.update(addArtworkToast.current, {
                render: "Saving", 
                isLoading: true
            });

            await AdminApiCall("POST", "/rest/artwork/addArtwork", formData).then(resp => {
                // console.log(resp);
                if (formData.progressStatus === "draft")
                    setTimeout(() => {
                        toast.update(addArtworkToast.current, {
                            render: formData.title + " saved as draft.",
                            type: 'success',
                            isLoading: false,
                            closeButton: true,
                            autoClose: 3000
                        });
                    }, 500);
                else
                    setTimeout(() => {
                        toast.update(addArtworkToast.current, {
                            render: formData.title + " published successfully.",
                            type: 'success',
                            isLoading: false,
                            closeButton: true,
                            autoClose: 3000,
                        });
                    }, 500);
                if(event.nativeEvent.submitter.value === "production")
                    navigate("/admin/manage-artworks");
                else
                    navigate("/admin/edit-artwork/"+resp.id);
            }).catch(e => {
                toast.update(addArtworkToast.current, {
                    render: e.response.data.message, type: 'error', 
                    isLoading: false,
                    closeButton: true
                });
                console.log(e);
            })
        } catch (e) {
            if (e.toString().includes("ValidationError")) {
                let validationErr = {}
                for (let i = 0; i < e.inner.length; i++) {
                    if(e.inner[i].path.includes("traits"))
                        validationErr["traits"] = e.inner[i].message;
                    else    
                        validationErr[e.inner[i].path] = e.inner[i].message;
                }
                setValidationError(validationErr);
                toast.update(addArtworkToast.current, {
                    render: "Please fill required fields correctly", 
                    type: 'error', 
                    isLoading: false,
                    closeButton: true
                });
            }
            else {
                toast.update(addArtworkToast.current, {
                    render: e.message, 
                    type: 'error', 
                    isLoading: false,
                    closeButton: true
                });
                console.log(e);
            }
        }
    }

    const getExistingTraits = async () => {
        setTraitsLoading(true);
        const traits = await ApiCall("GET", "/rest/trait-getter");
        
        if(traits) {
            let options = [];
            for(let i = 0; i < traits.length; i++) {
                options.push({
                    value: traits[i],
                    label: traits[i]
                });
            }
            setTraitOptions(options);
        }
        
        setTraitsLoading(false);
    }

    const getArtists = async () => {
        setArtistsLoading(true);
        
        const options = await ApiCall("GET", "/rest/artist/getAllArtist");
        
        setArtistOptions(options);
        setArtistsLoading(false);
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
    
        // Prevent the page/container scrolling
        // e.stopPropagation()
    
        // Refocus immediately, on the next tick (after the current function is done)
          setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    useEffect(() => {
        getArtists();
        getExistingTraits();
    }, []);

    return (
        <div className="add-artwork">
            <div className="header">
                <h1>Add Artwork</h1>
                <Link to={"/admin/add-artist"}><h2>Add Artist</h2></Link>
            </div>

            <form onSubmit={handleAddArtwork}>
                <div className="product-info">
                    <label htmlFor="prod-img" className="prod-img">
                        {uploadingImage ? 
                            <div className="upload-loader">
                                <AiOutlineLoading />
                                Uploading
                            </div> :
                            <>
                                <IoCloudUpload />
                                Upload Artwork
                            </>
                        }
                        <input type="file" accept="image/*" name="prod-img" onChange={handleImageUpload}/>
                        {artImage && <img src={artImage} className='prod-img-thumb'/>}
                        {validationError.imageRef && <span className="error">
                            <MdError />
                            {validationError.imageRef}
                        </span>}
                    </label>

                    <span>
                        <label htmlFor="prod-title">
                            <p>
                                Artwork Title
                                <span className="required">*</span>
                            </p>
                            <input type="text" name="title" maxLength="32" />
                            {validationError.title && <span className="error">
                                <MdError />
                                {validationError.title}
                            </span>}
                        </label>

                        <label htmlFor="prod-qty">
                            <p>
                                Quantity
                                <span className="required">*</span>
                            </p>
                            <input type="number" name="quantity" id="prod-qty" onWheel={numberInputOnWheelPreventChange}/>
                            {validationError.quantity && <span className="error">
                                <MdError />
                                {validationError.quantity}
                            </span>}
                        </label>

                        <label htmlFor="prod-description" className="description">
                            <p>
                                Artwork Description
                                <span className="required">*</span>
                            </p>
                            <textarea name="description" id="prod-des" cols="30" maxLength="500" placeholder="Artwork Description (Max 500 characters)" />
                            {validationError.description && <span className="error">
                                <MdError />
                                {validationError.description}
                            </span>}
                        </label>
                    </span>
                </div>

                <div className="product-details">
                    <label htmlFor="prod-artist">
                        <p>
                            Select Artist
                            <span className="required">*</span>
                        </p>
                        <Select 
                            isLoading={artistsLoading}
                            options={artistOptions} 
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            unstyled
                            placeholder={"Select Artist"}
                            name="artistId"
                        />
                        {validationError.artistId && <span className="error">
                            <MdError />
                            {validationError.artistId}
                        </span>}
                    </label>

                    <span className="traits-grid">
                        <label>
                            <p>
                                Add Traits 
                                <span className="required">*</span>
                            </p>
                        </label>

                        {traits.map((trait, i) => {
                                return (
                                    <span className="trait-row" key={i}>
                                        <CreatableSelect 
                                            isLoading={traitsLoading}
                                            options={traitOptions}
                                            value={trait.trait_type ? {value: trait.trait_type, label: trait.trait_type} : ""}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder={"Select Trait"}
                                            unstyled
                                            name="trait"
                                            onChange={(e) => handleTraitChange("trait_type", e.value, i)}
                                        />
                                        <input 
                                            type="text" 
                                            name="value" 
                                            value={trait.value || ""} 
                                            placeholder={"Value"}
                                            onChange={(e) => handleTraitChange(e.target.name, e.target.value, i)}
                                        />
                                        {i === 0 ? 
                                            <button 
                                                className="add-btn" 
                                                type="button" 
                                                onClick={() => addTraitField()}
                                            >
                                                    +
                                            </button> :
                                            <button 
                                                className="delete-btn" 
                                                type="button" 
                                                onClick={() => removeTraitField(i)}
                                            >
                                                <MdDeleteOutline />
                                            </button>
                                        }
                                    </span>
                                )
                            })
                        }
                        {validationError.traits && <div className="error">
                            <MdError />
                            {validationError.traits}
                        </div>}
                    </span>

                    <label htmlFor="price">
                        <p>
                            Price
                            <span className="required">*</span>
                        </p>
                        <input type="number" step="any" name="price" placeholder='Price in ETH' onWheel={numberInputOnWheelPreventChange}/>
                        {validationError.price && <span className="error">
                            <MdError />
                            {validationError.price}
                        </span>}
                    </label>

                    <div className="featured">
                        <span>
                            <input type="checkbox" name="isFeatured" />
                            <label>Featured</label>
                        </span>
                        <span>
                            <input type="checkbox" name="isTrending" />
                            <label>Trending</label>
                        </span>
                    </div>
                </div>

                <div className="btn-container">
                    <button className="save-btn" type="submit" value="draft">Save Draft</button>
                    <button className="publish-btn" type="submit" value="production">Publish</button>
                </div>
            </form>
        </div>
    )
}
