import { ethers, utils } from "ethers";
import abi from "./abi.json";

let contract;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

export const providerHandler = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const account = await provider.listAccounts();
    const address = account[0];
    const signer = provider.getSigner();
    contract = new ethers.Contract(contractAddress, abi, signer);
    return address;
};

// write functions
export const mint = async (price, account, tokenId, amount) => {
    console.log('Mint', account, amount, utils.parseEther((price*amount).toString()), tokenId);
    const n = await contract.mint(account, tokenId, amount, {value: utils.parseEther((price*amount).toString())});
    await n.wait();
    return n
}